<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header>
        <h1>{{ $t('message.notification_options') }}</h1>
      </header>
      <div class="mb-4">
        <p v-if="successMessage">
          <v-alert
            text
            dense
            color="teal"
            border="left"
            type="success"
          >
            {{ successMessage }}
          </v-alert>
        </p>
        <p v-if="errors.length">
          <v-alert
            text
            dense
            type="error"
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.title }}
          </v-alert>
        </p>
      </div>
      <div v-if="!user.rcu || $store.getters.isBudgetUser">
        <h5>{{ $t('message.my_ecoffre') }}</h5>
        <hr>
        <p>
          {{ $t('message.notification_email_login') }} <strong>{{ loginNotification ? $t('message.activated') : $t('message.deactivated') }}</strong>
          <v-switch v-model="loginNotification" v-on:change="putUser()"></v-switch>
        </p>
        <p v-if="!hasVaultProfile('USER', currentVault) || hasVaultProfile('OWNER', currentVault)">
          {{ $t('message.notification_email_new_document') }} <strong>{{ documentNotification ? $t('message.activated') : $t('message.deactivated') }}</strong>
          <v-switch v-model="documentNotification" v-on:change="putVault()"></v-switch>
        </p>
      </div>
      <div v-if="!hasVaultProfile('USER', currentVault)">
        <h5>{{ $t('message.my_secrets') }} <span v-if="user.rcu">{{ $t('message.business') }}</span></h5>
        <hr>
        <p>
          {{ $t('message.notification_email_secret_creation') }} <strong>{{ createSecretNotification ? $t('message.activated') : $t('message.deactivated') }}</strong>
          <v-switch v-model="createSecretNotification" v-on:change="putVault()"></v-switch>
        </p>
        <p>
          {{ $t('message.notification_email_secret_deletion') }} <strong>{{ deleteSecretNotification ? $t('message.activated') : $t('message.deactivated') }}</strong>
          <v-switch v-model="deleteSecretNotification" v-on:change="putVault()"></v-switch>
        </p>
        <p>
          {{ $t('message.notification_email_secret_view') }} <strong>{{ viewSecretNotification ? $t('message.activated') : $t('message.deactivated') }}</strong>
          <v-switch v-model="viewSecretNotification" v-on:change="putVault()"></v-switch>
        </p>
        <p>
          {{ $t('message.notification_email_secret_update_by_owner') }} <strong>{{ updateSecretByOwnerNotification ? $t('message.activated') : $t('message.deactivated') }}</strong>
          <v-switch v-model="updateSecretByOwnerNotification" v-on:change="putVault()"></v-switch>
        </p>
        <p v-if="user.rcu">
                  {{ $t('message.notification_email_secret_update_by_collab') }} <strong>{{ updateSecretByCollabNotification ? $t('message.activated') : $t('message.deactivated') }}</strong>
          <v-switch v-model="updateSecretByCollabNotification" v-on:change="putVault()"></v-switch>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'

export default {
  name: 'NotificationsOptions',
  data: () => ({
    loginNotification: false,
    documentNotification: false,
    secretNotification: false,
    createSecretNotification: false,
    deleteSecretNotification: false,
    viewSecretNotification: false,
    updateSecretByOwnerNotification: false,
    updateSecretByCollabNotification: false,
    successMessage: null,
    loading: false,
    errors: []
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)
    this.getUser()
    this.getVault()
    document.title = config.title + ' - ' + this.$t('message.notification_options')
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    currentVault () {
      return this.$store.getters.getCurrentVault
    }
  },
  methods: {
    hasVaultProfile (profile) {
      const vaultsRole = this.currentVault.vaultsRole
      for (let i = 0; i < vaultsRole.length; i++) {
        if (vaultsRole[i].profiles.includes(profile)) {
          return true
        }
      }

      return false
    },
    getUser () {
      const user = this.$store.getters.getUser

      if (!user) {
        return
      }

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/users/' + user.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const user = response.data

            if (user) {
              this.loginNotification = user.loginNotification
            }
          }

          this.loading = false
        })
        .catch(e => {
          this.loading = false
        })
    },
    putUser () {
      const user = this.$store.getters.getUser

      const data = {
        loginNotification: this.loginNotification
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/users/' + user.id, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken,
            'Content-Type': 'multipart/form-data'
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            const user = response.data

            if (user) {
              localStorage.setItem('user', JSON.stringify(user))

              this.successMessage = this.$t('message.notification_option_updated')
              this.errors = []
            }
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          this.loading = false
        })
    },
    getVault () {
      const currentVault = this.$store.getters.getCurrentVault

      if (!currentVault) {
        return
      }

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/vaults/' + currentVault.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const vault = response.data

            if (vault) {
              this.documentNotification = vault.documentNotification
              this.createSecretNotification = vault.createSecretNotification
              this.deleteSecretNotification = vault.deleteSecretNotification
              this.viewSecretNotification = vault.viewSecretNotification
              this.updateSecretByOwnerNotification = vault.updateSecretByOwnerNotification
              this.updateSecretByCollabNotification = vault.updateSecretByCollabNotification
            }
          }

          this.loading = false
        })
        .catch(e => {
          this.loading = false
        })
    },
    putVault () {
      const currentVault = this.$store.getters.getCurrentVault

      const data = {
        documentNotification: this.documentNotification,
        createSecretNotification: this.createSecretNotification,
        deleteSecretNotification: this.deleteSecretNotification,
        viewSecretNotification: this.viewSecretNotification,
        updateSecretByOwnerNotification: this.updateSecretByOwnerNotification,
        updateSecretByCollabNotification: this.updateSecretByCollabNotification
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/vaults/' + currentVault.id, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken,
            'Content-Type': 'multipart/form-data'
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            const vault = response.data

            if (vault) {
              localStorage.setItem('current_vault', JSON.stringify(vault))

              this.successMessage = this.$t('message.notification_option_updated')
              this.errors = []
            }
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.loading = false
        })
    }
  }
}
</script>
